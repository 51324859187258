// @flow
import React from 'react';
import styled from 'styled-components';
import { media } from '../theme';
import { SubHeader } from '.';

const SubHeaderContainer = styled.div`
  width: 80%;
  ${media.max.large`
    width: 90%;
  `};
  display: flex;
  border-radius: 12px 12px 0 0;
  background: linear-gradient(180deg, #304558 0%, #38536c 100%);
  margin-top: 20px;
  justify-content: center;
  align-content: center;
  height: 252px;
  align-items: center;
`;

const InnerSubHeaderContainer = styled.div`
  margin-left: 4em;
  margin-right: 4em;
  width: 100%;
  ${media.max.large`
    flex-direction: column;
  `};
  display: flex;
  justify-content: space-between;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-self: flex-end;
  ${media.max.large`
    margin-top:20px;
  `};
`;

const Title = styled.div`
  color: #ffffff;
  font-size: 24px;
  font-weight: bold;
  line-height: 34px;
  margin-bottom: 20px;
`;

const Description = styled.div`
  color: #ffffff;
  font-size: 16px;
  line-height: 24px;
`;

const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 327px;
`;

const PageHeader = ({
  title,
  description,
  children,
}: {
  title: string,
  description: string,
  children: Node,
}) => {
  return (
    <SubHeader>
      <SubHeaderContainer>
        <InnerSubHeaderContainer>
          <HeaderContent>
            <Title>{title}</Title>
            <Description>{description}</Description>
          </HeaderContent>
          <ButtonContainer>{children}</ButtonContainer>
        </InnerSubHeaderContainer>
      </SubHeaderContainer>
    </SubHeader>
  );
};

export { PageHeader };
