// @flow
import React, { useContext, useEffect } from 'react';
import { navigate } from '@reach/router';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import useAxios from 'axios-hooks';

import { ConfigContext } from '../../ConfigProvider';
import { error } from '../../log';
import { useFormMachine } from './hooks';
import { media } from '../../theme';
import {
  Page,
  PageHeader,
  PrimaryButton,
  SecondaryButton,
  PasswordResetButton,
} from '../../components';
import { UserForm } from '../../components/Form';
import { Spinner } from '../../components/Spinner';
import SpinnerIcon from '../../assets/spinner-large-blue.svg';
import { InfoBox } from '../../components/InfoBox';
import { RemoveUser } from '../RemoveUser';

const PrimaryActionButton = styled(PrimaryButton)`
  margin-left: 14px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  ${media.max.small`
    min-height: 100vh;
    flex-direction: column-reverse;
  `};
  width: 80%;
  ${media.max.large`
    width: 90%;
  `};
  background-color: white;
  border-radius: 0 0 12px 12px;
  margin-bottom: 20px;
  justify-content: flex-start;
`;

const UserDetails = styled.div`
  width: 40%;
  margin-left: 4em;
  margin-top: 36px;
  margin-right: 50px;
  margin-bottom: 180px;
  ${media.max.small`
    width: auto;
    border-left: 0;
    padding: 0;   
    margin: 0 1em 150px 1em; 
  `};
`;

const InfoBoxContainer = styled.div`
  display: flex;
  width: 40%;
  justify-content: center;
  border-left: 0.5px solid #b0bcc7;
  max-height: 310px;
  margin-top: 50px;
  margin-right: 4em;
  margin-bottom: 4em;
  padding-top: 34px;
  padding-left: 50px;
  ${media.max.small`
    width: auto;
    border-left: 0;
    padding: 0;
    margin: 20px 1em 20px 1em;    
  `};
`;

const Amend = ({ email, dealerId }: { email: string, dealerId: string }) => {
  const config = useContext(ConfigContext);
  const disabledFields = ['email'];

  const [
    { data: userData, loading: isLoadingUser, error: getUserError },
    refetch,
  ] = useAxios(
    `${config.api.domain}${config.api.dealerships}${dealerId}${
      config.api.user
    }${email}`,
  );

  useEffect(() => {
    if (!isLoadingUser) refetch();
    // eslint-disable-next-line
  }, []);

  if (getUserError) error(getUserError);

  const form = useForm();
  const { handleSubmit } = form;
  const { isSaving, onSubmit } = useFormMachine({ method: 'PUT', dealerId });
  const goBack = event => {
    event.preventDefault();
    return navigate('/');
  };

  return (
    <Page testId="amend-user">
      <PageHeader
        title="Edit an existing user"
        description="Make some changes to a user who is already registered."
      >
        <SecondaryButton onClick={goBack}>Cancel</SecondaryButton>
        <PrimaryActionButton onClick={handleSubmit(onSubmit)}>
          {!isSaving ? (
            <span>Confirm changes</span>
          ) : (
            <Spinner alt="Loading..." />
          )}
        </PrimaryActionButton>
      </PageHeader>
      <ContentContainer>
        <UserDetails>
          {isLoadingUser ? (
            <Spinner icon={SpinnerIcon} />
          ) : (
            <UserForm
              title="User details"
              form={form}
              user={userData}
              disabledFields={disabledFields}
            />
          )}
          <RemoveUser
            email={email}
            dealerId={dealerId}
            onChange={() => navigate('/')}
          />
        </UserDetails>
        <InfoBoxContainer>
          <InfoBox
            title="INFO"
            description="If this user requires a password reset link, please click the button below to send an email containing instructions to this user."
          >
            <PasswordResetButton email={email} />
          </InfoBox>
        </InfoBoxContainer>
      </ContentContainer>
    </Page>
  );
};

export { Amend };
