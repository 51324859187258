// @flow
import React from 'react';
import styled from 'styled-components';
import spinnerSvg from '../assets/spinner-small-white.svg';

const Container = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100%;
  position: ${({ position }) => position || 'static'};
  margin-left: ${({ offsetX }) => offsetX || 0};
  margin-top: ${({ offsetY }) => offsetY || 0};
`;

const SpinnerImg = styled.img``;

const Spinner = ({
  icon,
  position,
  offsetX,
  offsetY,
}: {
  icon?: Object,
  position?: string,
  offsetX?: string,
  offsetY?: string,
}) => {
  return (
    <Container position={position} offsetX={offsetX} offsetY={offsetY}>
      <SpinnerImg
        data-testid="loading-spinner"
        src={icon || spinnerSvg}
        alt="loading"
      />
    </Container>
  );
};

export { Spinner };
