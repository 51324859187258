// @flow
import React, { useContext } from 'react';
import { navigate } from '@reach/router';
import styled, { createGlobalStyle } from 'styled-components';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useAxios from 'axios-hooks';
import { RR_BRAND_ID, AML_BRAND_ID } from '../../brands';
import { useFormMachine } from './hooks';
import { error } from '../../log';
import { ConfigContext } from '../../ConfigProvider';
import { media } from '../../theme';
import { Spinner } from '../../components/Spinner';
import SpinnerIcon from '../../assets/spinner-large-blue.svg';
import { InfoBox } from '../../components/InfoBox';
import {
  Page,
  PageHeader,
  PrimaryButton,
  SecondaryButton,
} from '../../components';
import { UserForm } from '../../components/Form';

const GlobalStyle = createGlobalStyle`
  body {
    background-image: none;
    background-color: #e1e7ed;
  }
`;

const PrimaryActionButton = styled(PrimaryButton)`
  margin-left: 14px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  ${media.max.small`
    height: 100%;
    flex-direction: column-reverse;
  `};
  width: 80%;
  ${media.max.large`
    width: 90%;
  `};
  background-color: white;
  border-radius: 0 0 12px 12px;
  margin-bottom: 20px;
  justify-content: space-evenly;
`;

const UserDetails = styled.div`
  width: 50%;
  margin-left: 4em;
  margin-top: 36px;
  margin-right: 50px;
  margin-bottom: 180px;
  ${media.max.small`
    width: auto;
    border-left: 0;
    padding: 0;
    margin: 0 1em 20px 1em;
  `};
`;

const InfoBoxContainer = styled.div`
  display: flex;
  width: 50%;
  justify-content: center;
  border-left: 0.5px solid #b0bcc7;
  max-height: 310px;
  margin-top: 50px;
  margin-right: 4em;
  padding-top: 34px;
  padding-left: 50px;
  ${media.max.small`
    width: auto;
    border-left: 0;
    padding: 0;
    margin: 20px 1em 20px 1em;    
  `};
`;

const Add = () => {
  const { t } = useTranslation();

  const config = useContext(ConfigContext);
  const form = useForm();
  const { handleSubmit } = form;
  const { isSaving, onSubmit } = useFormMachine({ method: 'POST' });
  const goBack = event => {
    event.preventDefault();
    return navigate('/');
  };
  const [{ data, loading: isLoading, error: getError }] = useAxios({
    method: 'POST',
    url: config.staffAreaGql,
    data: {
      query: `
          query ImsFranchiseDealers {
            vImsFranchiseDealers(filter: {franchiseId: {in: ["${RR_BRAND_ID}","${AML_BRAND_ID}"]}}, orderBy: DEALER_NAME_ASC) {
              nodes {
                key: dealerId
                label: dealerName
                brandId: franchiseId
              }
            }
          }
        `,
    },
  });

  const dealers = (data && data.data.vImsFranchiseDealers.nodes) || [];

  if (getError) error(getError);

  return (
    <>
      <GlobalStyle />
      <Page testId="add-user">
        <PageHeader title={t('addUser')} description={t('addUserDescription')}>
          <SecondaryButton onClick={goBack}>{t('cancel')}</SecondaryButton>
          <PrimaryActionButton onClick={handleSubmit(onSubmit)}>
            {!isSaving ? (
              <span>{t('addUser')}</span>
            ) : (
              <Spinner alt={t('loading')} />
            )}
          </PrimaryActionButton>
        </PageHeader>
        <ContentContainer>
          <UserDetails>
            {isLoading ? (
              <Spinner icon={SpinnerIcon} />
            ) : (
              <UserForm
                title={t('userDetails')}
                form={form}
                assignedTo={dealers}
              />
            )}
          </UserDetails>
          <InfoBoxContainer>
            <InfoBox
              title={t('infoBoxTitle')}
              description={t('infoBoxDescription')}
            />
          </InfoBoxContainer>
        </ContentContainer>
      </Page>
    </>
  );
};

export { Add };
