// @flow
import React from 'react';
import styled from 'styled-components';

import { FormField } from '.';

const Input = styled.input`
  padding: 9px;
  min-height: 30px;
  width: 100%;
  padding: 0 9px;
  box-sizing: border-box;
  border: none;
  outline: none;
  box-shadow: none;
`;

type Props = {
  label: string,
  required: boolean,
  type: string,
  error?: string,
  disabled: boolean,
  placeholder: string,
  reference: any,
  name: string,
};

const InputField = ({
  label,
  required,
  type,
  error,
  disabled,
  placeholder,
  reference,
  name,
}: Props) => {
  return (
    <FormField
      label={label}
      required={required}
      error={error}
      disabled={disabled}
    >
      <Input
        type={type}
        readOnly={disabled}
        placeholder={placeholder}
        ref={reference}
        name={name}
        error={error}
        data-testid={name}
      />
    </FormField>
  );
};

export { InputField };
