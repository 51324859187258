import React from 'react';
import styled from 'styled-components';
import { store } from 'react-notifications-component';
import IconClose from '../assets/notification_close.svg';

const NotificationContainer = styled.div`
  width: 390px;
  height: auto;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  background-color: #37526b;
`;
const Header = styled.div`
  color: ${({ color }) => color || '#ffffff'};
  font-size: 15px;
  letter-spacing: 0;
  line-height: 22px;
  font-weight: 600;
  width: 267px;
  margin-bottom: 4px;
`;
const Description = styled.div`
  opacity: 0.76;
  color: #ffffff;
  width: 267px;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
`;
const Col = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 267px;
  margin-top: 26px;
  margin-bottom: 26px;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
`;
const Icon = styled.img`
  height: 28px;
  margin: 22px;
`;
const CloseIcon = styled.img`
  box-sizing: border-box;
  height: 36px;
  align-self: baseline;
  margin: 1px;
`;

const Notification = ({ color, title, description, icon }) => {
  return ({ id }) => (
    <NotificationContainer data-testid="notification">
      <Row>
        <Icon src={icon} data-testid="icon" />
        <Col>
          <Header color={color}>{title}</Header>
          <Description>{description}</Description>
        </Col>
        <CloseIcon
          src={IconClose}
          onClick={() => store.removeNotification(id)}
          data-testid="close-notification"
        />
      </Row>
    </NotificationContainer>
  );
};
export { Notification };
