// @flow
import { partial, compose } from 'ramda';
import { serializeError } from 'serialize-error';

const namespace = 'staff-area-ui';
const prefix = `${namespace}:`;
const shouldLog = (checkKey, checkValue) =>
  localStorage.getItem(checkKey) === checkValue;

const nullConsole = {
  info: () => {},
  warn: () => {},
  error: () => {},
};

const logger = level => (...args) =>
  shouldLog('debug', namespace)
    ? // eslint-disable-next-line no-console
      console[level](...args)
    : nullConsole[level](...args);

const logInfo = logger('info');
const logWarn = logger('warn');
const logError = logger('error');

const info = partial(logInfo, [prefix]);
const warn = partial(logWarn, [prefix]);
const logPrefixedError = partial(logError, [prefix]);
const error = compose(
  logPrefixedError,
  serializeError,
);

export { info, warn, error, namespace };
