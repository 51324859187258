// @flow
import { useContext } from 'react';
import { useMachine } from '@xstate/react';
import axios from 'axios';
import { store } from 'react-notifications-component';
import { navigate } from '@reach/router';
import { ConfigContext } from '../../ConfigProvider';
import { createFormMachine } from '../../machines/formMachine';
import { useAuthState, useUserState } from '../../auth';
import { getBrands } from '../../brands';
import { Notification } from '../../components/Notification';
import IconSuccess from '../../assets/notification_success.svg';
import IconError from '../../assets/notification_error.svg';

const transformUser = ({
  firstName,
  lastName,
  email,
  dealership,
}: {
  firstName: string,
  lastName: string,
  email: string,
  dealership: Object,
}) => ({
  firstName,
  lastName,
  email,
  dealership: {
    ...dealership,
    brandName: getBrands()[dealership.brand].name,
    logo: getBrands()[dealership.brand].logo,
  },
});

const useFormMachine = ({
  method,
  dealerId,
}: {
  method: string,
  dealerId: string,
}) => {
  const {
    context: { token },
  } = useAuthState();
  const config = useContext(ConfigContext);
  const { dealerships } = useUserState();
  const url = `${config.api.domain}${config.api.dealerships}${dealerId}`;

  const submitRequest = async user => {
    const payload = {
      ...user.payload,
      dealership: dealerships[dealerId],
    };
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      return axios({
        method,
        url,
        data: transformUser(payload),
        headers,
      });
    } catch (err) {
      throw new Error(err);
    }
  };

  const notification = {
    insert: 'top',
    container: 'top-right',
    animationIn: ['animated', 'fadeIn'],
    animationOut: ['animated', 'fadeOut'],
    width: 390,
    dismiss: {
      duration: 5000,
    },
  };

  const success = {
    title: 'New user added',
    description:
      'This user has been successfully added. Details have been sent to the email address provided.',
  };

  const error = {
    title: 'Something went wrong',
    description:
      'We were unable to create this user. Please check your connection and try again.',
  };

  switch (method) {
    case 'PUT':
      success.title = 'Changes saved';
      success.description =
        'The updates you made to this user have been saved.';
      error.description =
        'We were unable to edit this user. Please check your connection and try again.';
      break;
    default:
      break;
  }

  const onSuccess = () => {
    store.addNotification({
      ...notification,
      content: Notification({
        color: '#4AC57E',
        title: success.title,
        description: success.description,
        icon: IconSuccess,
      }),
    });
    navigate('/');
  };

  const onError = () =>
    store.addNotification({
      ...notification,
      content: Notification({
        color: '#FF6767',
        title: error.title,
        description: error.description,
        icon: IconError,
      }),
    });

  const [current, send] = useMachine(
    createFormMachine(() => {}, submitRequest, onSuccess, onError),
  );

  const isSaving = current.matches('submitting');
  const isLoading = current.matches('loading');

  const onSubmit = (form: { form: Object }) => {
    send({ type: 'SUBMIT', payload: form });
  };

  return { current, send, isSaving, onSubmit, isLoading };
};

export { useFormMachine };
