// @frow
const RR_BRAND_ID = 34;
const AML_BRAND_ID = 2;

const brands = {
  2: {
    id: 2,
    name: 'Aston Martin',
    logo:
      'https://res.cloudinary.com/motortrak/q_auto,f_auto/aston-martin/ims-logo.png',
  },
  34: {
    id: 34,
    name: 'Rolls-Royce',
    logo:
      'https://res.cloudinary.com/motortrak/image/upload/v1598353272/rolls-royce/RR_Monogram_RGB_NEG_.svg',
  },
};

const getBrands = () => brands;

export { getBrands, RR_BRAND_ID, AML_BRAND_ID };
