// @flow
import React from 'react';
import { navigate } from '@reach/router';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useFormMachine } from './hooks';
import { Spinner } from '../../components/Spinner';
import { media } from '../../theme';
import {
  Page,
  PageHeader,
  PrimaryButton,
  SecondaryButton,
} from '../../components';
import { UserForm } from '../../components/Form';
import { InfoBox } from '../../components/InfoBox';

const PrimaryActionButton = styled(PrimaryButton)`
  margin-left: 14px;
`;

const ContentContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  ${media.max.small`
    height: 100%;
    flex-direction: column-reverse;
  `};
  width: 80%;
  ${media.max.large`
    width: 90%;
  `};
  background-color: white;
  border-radius: 0 0 12px 12px;
  margin-bottom: 20px;
  justify-content: space-evenly;
`;

const UserDetails = styled.div`
  width: 50%;
  margin-left: 4em;
  margin-top: 36px;
  margin-right: 50px;
  ${media.max.small`
    width: auto;
    border-left: 0;
    padding: 0;
    margin: 0 1em 20px 1em;
  `};
`;

const InfoBoxContainer = styled.div`
  display: flex;
  width: 50%;
  justify-content: center;
  border-left: 0.5px solid #b0bcc7;
  max-height: 250px;
  margin-top: 50px;
  margin-right: 4em;
  padding-top: 34px;
  padding-left: 50px;
  ${media.max.small`
    width: auto;
    border-left: 0;
    padding: 0;
    margin: 20px 1em 20px 1em;    
  `};
`;

const Add = ({ dealerId }: { dealerId: string }) => {
  const form = useForm();
  const { t } = useTranslation();
  const { handleSubmit } = form;
  const { isSaving, onSubmit } = useFormMachine({ method: 'POST', dealerId });
  const goBack = event => {
    event.preventDefault();
    return navigate('/');
  };

  return (
    <Page testId="add-user">
      <PageHeader title={t('AddNewUser')} description={t('addUserDescription')}>
        <SecondaryButton onClick={goBack}>{t('cancel')}</SecondaryButton>
        <PrimaryActionButton onClick={handleSubmit(onSubmit)}>
          {!isSaving ? (
            <span>{t('addUser')}</span>
          ) : (
            <Spinner alt={t('loading')} />
          )}
        </PrimaryActionButton>
      </PageHeader>
      <ContentContainer>
        <UserDetails>
          <UserForm title={t('userDetails')} form={form} />
        </UserDetails>
        <InfoBoxContainer>
          <InfoBox
            title={t('infoBoxTitle')}
            description={t('infoBoxDescription')}
          />
        </InfoBoxContainer>
      </ContentContainer>
    </Page>
  );
};

export { Add };
