// @flow
import React from 'react';
import styled from 'styled-components';
import IconLock from '../../assets/icon_lock.svg';

const FormFieldContainer = styled.fieldset`
  min-height: 50px;
  padding: 0px;
  border: 1px solid ${props => (props.error ? '#FF6767' : '#BDC7D3')};
  border-radius: 2px;
  background-color: ${props =>
    props.disabled ? 'rgba(187,202,214,0.2)' : '#ffffff'};
  color: #2f3031;
  margin-bottom: 10px;
`;

const Label = styled.label`
  font-size: 11px;
  font-weight: 600;
  ${({ error }) =>
    error &&
    `
    color: #ff6767;
  `}
  ${({ required }) =>
    required &&
    `
    &:after {
      content: ' *';
      color: #ff6767;
    }
  `}
`;

const DisabledIcon = styled.div`
  display: flex;
  width: 11px;
  background-image: url(${IconLock});
  background-repeat: no-repeat;
  height: 15px;
  padding: 10px;
`;

const InputError = styled.div`
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
  text-transform: uppercase;
  color: #ff6767;
`;

const ChildContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`;

const FieldContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: calc(100% - 15px);
  align-items: center;
`;

const Legend = styled.legend`
  display: flex;
  height: auto;
`;

type FormFieldProps = {
  label: string,
  required: boolean,
  error: string,
  children: any,
  direction: string,
  disabled: Boolean,
};

const FormField = ({
  label,
  required,
  children,
  error,
  disabled,
}: FormFieldProps) => {
  return (
    <FormFieldContainer error={error} disabled={disabled}>
      <Legend>
        <Label error={error} required={required}>
          {label}
        </Label>
      </Legend>
      <FieldContainer>
        <ChildContainer>{children}</ChildContainer>
        {disabled && <DisabledIcon />}
        {error && <InputError>Required</InputError>}
      </FieldContainer>
    </FormFieldContainer>
  );
};

export { FormField };
