// @flow
import React, { useContext } from 'react';
import { navigate } from '@reach/router';
import styled from 'styled-components';
import { store } from 'react-notifications-component';
import useAxios from 'axios-hooks';

import { error } from '../log';
import { ConfigContext } from '../ConfigProvider';
import { Notification } from './Notification';
import { Spinner } from './Spinner';
import IconSuccess from '../assets/notification_success.svg';
import IconError from '../assets/notification_error.svg';
import SpinnerIconSmall from '../assets/spinner-small-blue.svg';

const Button = styled.button`
  cursor: pointer;
  background: transparent;
  border: 1px solid #bbcad7;
  border-radius: 4px;
  padding: 5px 10px;
  min-height: 38px;
  min-width: 155px;
  color: #2f3031;
  font-family: 'Fira Sans';
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 17px;
  text-align: center;
`;

const notification = {
  insert: 'top',
  container: 'top-right',
  animationIn: ['animated', 'fadeIn'],
  animationOut: ['animated', 'fadeOut'],
  width: 390,
  dismiss: {
    duration: 5000,
  },
};

const onSuccess = ({
  title,
  description,
  redirectTo,
}: {
  title: string,
  description: string,
  redirectTo?: string,
}) => {
  store.addNotification({
    ...notification,
    content: Notification({
      color: '#4AC57E',
      title,
      description,
      icon: IconSuccess,
    }),
  });
  if (redirectTo) navigate(redirectTo);
};

const onError = ({
  title,
  description,
  redirectTo,
}: {
  title: string,
  description: string,
  redirectTo?: string,
}) => {
  store.addNotification({
    ...notification,
    content: Notification({
      color: '#FF6767',
      title,
      description,
      icon: IconError,
    }),
  });
  if (redirectTo) navigate(redirectTo);
};

const PasswordResetButton = ({ email }: { email: string }) => {
  const config = useContext(ConfigContext);
  const [
    { loading: isSendingPasswordRequest, error: passwordRequestError },
    executePasswordRequest,
  ] = useAxios(
    {
      method: 'POST',
      url: `${config.api.domain}${config.api.passwordReset}`,
    },
    { manual: true },
  );

  const handlePasswordRequest = async () => {
    try {
      await executePasswordRequest({ data: { email } });
      onSuccess({
        title: 'Password reset sent',
        description: 'The password reset request was successfuly sent.',
      });
    } catch (err) {
      onError({
        title: 'Something went wrong',
        description:
          'We were unable to send the password reset request. Please check your connection and try again.',
      });
      error(passwordRequestError);
    }
  };

  return (
    <Button onClick={() => handlePasswordRequest()}>
      {isSendingPasswordRequest ? (
        <Spinner icon={SpinnerIconSmall} />
      ) : (
        `Send password reset`
      )}
    </Button>
  );
};

export { PasswordResetButton };
