// @flow
import React, { Fragment } from 'react';
import styled from 'styled-components';
import { useTable, useFlexLayout } from 'react-table';
import { useWindowSize } from '../../theme';
import type { Column } from './types';

const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const TTable = styled.table`
  text-align: left;
  font-size: 12px;
  overflow: hidden;
  border-radius: 12px;
  border-spacing: 0;
  width: 100%;
  padding-bottom: 20px;
`;

const THead = styled.thead`
  background-image: linear-gradient(#304558, #38536c);
  color: #ffffff;
  line-height: 14px;
  font-size: 10px;
`;

const THeadRow = styled.tr`
  padding: 2px;
  text-transform: uppercase;
  height: 36px;
`;

const TRow = styled.tr`
  height: 44px;
  background-color: #f6f6f6;
  border-radius: 3px;
`;

const THeadColumn = styled.th`
  padding: 0.5rem;
  font-weight: normal;
  width: 85px;
`;

const TColumn = styled.td`
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  align-items: center;
  display: flex;
`;

const TBody = styled.tbody`
  display: block;
  background-color: white;
  padding: 2px;
`;

const TEmptyRow = styled.tr`
  height: 2px;
`;

const Table = ({
  columns,
  data,
}: {
  columns: Array<Column>,
  data: Array<Object>,
}) => {
  const defaultColumn = {
    width: 150,
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setHiddenColumns,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
    },
    useFlexLayout,
  );

  const windowSize = useWindowSize();

  React.useEffect(() => {
    setHiddenColumns(
      columns
        .filter(
          c => c.hiddenScreenSizes && c.hiddenScreenSizes.includes(windowSize),
        )
        .map(c => c.id),
    );
  }, [windowSize, setHiddenColumns, columns]);

  return (
    <Container>
      <TTable {...getTableProps()} className="noSpace">
        <THead>
          {headerGroups.map(headerGroup => (
            <THeadRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <THeadColumn {...column.getHeaderProps()}>
                  {column.render('Header')}
                </THeadColumn>
              ))}
            </THeadRow>
          ))}
        </THead>
        <TBody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            const modifiedRow = {
              ...row,
              position: i + 1,
            };

            prepareRow(modifiedRow);
            return (
              <Fragment key={modifiedRow.position}>
                <TRow {...modifiedRow.getRowProps()}>
                  {modifiedRow.cells.map(cell => (
                    <TColumn
                      key={`${cell.column.id}-${cell.row.id}`}
                      {...cell.getCellProps()}
                    >
                      {cell.render('Cell')}
                    </TColumn>
                  ))}
                </TRow>
                <TEmptyRow />
              </Fragment>
            );
          })}
        </TBody>
      </TTable>
    </Container>
  );
};

export { Table };
