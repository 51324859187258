// @flow
import React from 'react';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import { ModalProvider } from 'react-modal-hook';
import { AuthProvider, UserProvider, useAuthMachine } from './auth';
import { Site } from './pages/Site';
import { info, error } from './log';
import { ConfigProvider } from './ConfigProvider';

import './localisation/initialisation';

const { global, auth0 } = window.config;
const loginClientOptions = {
  ...auth0,
  redirect_uri: window.location.origin,
  cacheLocation: 'localstorage',
  useRefreshTokens: true,
};

info('app starting...');
const App = () => {
  const authMachine = useAuthMachine(loginClientOptions, error);
  const { context } = authMachine;

  return (
    <AuthProvider state={authMachine}>
      <UserProvider state={context}>
        <ConfigProvider config={global}>
          <ModalProvider>
            <ReactNotification />
            <Site />
          </ModalProvider>
        </ConfigProvider>
      </UserProvider>
    </AuthProvider>
  );
};

export { App };
