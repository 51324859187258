import React, { useContext } from 'react';
import { useModal } from 'react-modal-hook';
import styled from 'styled-components';
import useAxios from 'axios-hooks';
import { store } from 'react-notifications-component';
import { useTranslation } from 'react-i18next';
import { ConfirmDialog, Notification, PrimaryButton } from '../components';
import { error } from '../log';
import { ConfigContext } from '../ConfigProvider';
import IconSuccess from '../assets/notification_success.svg';
import IconError from '../assets/notification_error.svg';
import { ReactComponent as IconRemove } from '../assets/icon_remove.svg';

const RemoveButton = styled(PrimaryButton)`
  border: 1px solid #ff6767;
  border-radius: 4px;
  background-color: transparent;
  color: #ff6767;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 100;
  width: 127px;
  justify-content: space-evenly;
  margin-left: 14px;
`;

const RemoveUser = ({ email, dealerId, onChange }) => {
  const config = useContext(ConfigContext);
  const { t } = useTranslation();
  const [{ error: deleteError }, executeDelete] = useAxios(
    {
      url: `${config.api.domain}${
        config.api.dealerships
      }${dealerId}/user/${email}`,
      method: 'DELETE',
    },
    { manual: true },
  );

  const notification = {
    insert: 'top',
    container: 'top-right',
    animationIn: ['animated', 'fadeIn'],
    animationOut: ['animated', 'fadeOut'],
    width: 390,
    dismiss: {
      duration: 5000,
    },
  };

  const onSuccess = () => {
    store.addNotification({
      ...notification,
      content: Notification({
        color: '#4ac57e',
        title: t('removerUserSuccessTitle'),
        description: t('removerUserSuccessDescription'),
        icon: IconSuccess,
      }),
    });
  };

  const onError = () =>
    store.addNotification({
      ...notification,
      content: Notification({
        color: '#4ac57e',
        title: t('removerUserErrorTitle'),
        description: t('removerUserTitleDescription'),
        icon: IconError,
      }),
    });

  async function deleteUser() {
    try {
      await executeDelete();
      onSuccess();
      // eslint-disable-next-line no-use-before-define
      hideConfirmModal();
      onChange();
    } catch (err) {
      onError();
      error(deleteError);
    }
  }
  const [showConfirmModal, hideConfirmModal] = useModal(
    ({ in: open, onExited }) => (
      <ConfirmDialog
        open={open}
        onExited={onExited}
        title={`${t('removeUserConfirmTitle')} ${email}`}
        description={t('removeUserConfirmDescription')}
        confirmLabel={t('removeUserButtonLabel')}
        onConfirm={deleteUser}
        DialogIcon={IconRemove}
        ConfirmIcon={IconRemove}
        onCancel={hideConfirmModal}
      />
    ),
    [email],
  );

  return (
    <RemoveButton onClick={showConfirmModal}>
      <IconRemove />
      {t('removeUserButtonLabel')}
    </RemoveButton>
  );
};
export { RemoveUser };
