// @flow
import styled from 'styled-components';

const Checkbox = styled.div`
    border: 1px solid #9EADBA;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 16px;
    width: 16px;
    &::after {
        content: "";
        display: flex;
        width: 10px;
        height: 10px;
        background: ${props => (props.isChecked ? '#007AFF;' : '#FFFFFF')};
    }
}
`;

export { Checkbox };
