import React from 'react';
import styled from 'styled-components';

const Label = styled.div`
  border: 1px solid ${({ colour }) => colour || '#000000'};
  border-radius: 3px;
  font-size: 10px;
  padding: 3px;
  color: ${({ colour }) => colour || '#000000'};
`;

const StatusLabel = ({ label, colour }) => (
  <Label colour={colour}>{label}</Label>
);

export { StatusLabel };
