// @flow
import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { theme } from '../theme';
import { Spinner } from '../components/Spinner';
import SpinnerIcon from '../assets/spinner-large-white.svg';

const Container = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  min-height: 100vh;
`;

const GlobalStyle = createGlobalStyle`
  body {
    background-image: url(${theme.header.bgImage});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
`;

const UnauthenticatedRoutes = () => (
  <>
    <GlobalStyle />
    <Container data-testid="unauthenticated-routes">
      <Spinner icon={SpinnerIcon} />
    </Container>
  </>
);

export { UnauthenticatedRoutes };
