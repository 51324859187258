// @flow
import { Machine, assign } from 'xstate';
import createAuth0Client from '@auth0/auth0-spa-js';
import axios from 'axios';
import type { ClientOptions, Logger } from './types';

import { getDealerships } from './getDealerships';

const createAuthMachine = (
  loginClientOptions: ClientOptions,
  error: Logger = () => {},
) =>
  Machine(
    {
      id: 'auth',
      initial: 'initializing',
      context: {
        clientOptions: { ...loginClientOptions },
        loginProps: {
          destination: '/',
          location: window.location,
        },
        isSuperAdmin: false,
        dealerCount: 0,
        dealerId: 0,
        makeId: 0,
        dealerships: null,
      },
      states: {
        initializing: {
          initial: 'creating',
          states: {
            creating: {
              invoke: {
                src: 'createClient',
                onDone: {
                  actions: assign({ authClient: (_, { data }) => data }),
                  target: 'checkIfRedirectFromIdP',
                },
                onError: {
                  actions: 'failed',
                },
              },
            },
            checkIfRedirectFromIdP: {
              invoke: {
                id: 'checkIfRedirectFromIdP',
                src: 'checkIfRedirectFromIdP',
                onDone: [
                  {
                    cond: 'isTrue',
                    target: '#auth.authenticating.handleRedirect',
                  },
                  {
                    target: '#auth.authenticating.getToken',
                  },
                  {
                    target: '#auth.authenticating.getDealerships',
                  },
                ],
                onError: {
                  actions: 'failed',
                },
              },
            },
          },
        },
        authenticating: {
          states: {
            getToken: {
              invoke: {
                id: 'getToken',
                src: 'getToken',
                onDone: [
                  {
                    target: '#auth.authenticated',
                    actions: assign({ token: (_, { data }) => data }),
                  },
                ],
                onError: {
                  target: '#auth.authenticating.redirectToIdP',
                },
              },
            },
            getDealerships: {
              invoke: {
                id: 'getDealerships',
                src: 'getDealerships',
                onDone: {
                  target: '#auth.authenticated',
                  actions: assign((_, { data }) => data),
                },
                onError: {
                  target: '#auth.authenticating.redirectToIdP',
                },
              },
            },
            handleRedirect: {
              invoke: {
                src: 'handleRedirect',
                onDone: {
                  target: '#auth.authenticated',
                },
                onError: {
                  actions: 'logError',
                },
              },
            },
            redirectToIdP: {
              entry: 'redirectToIdP',
            },
          },
        },
        authenticated: {
          initial: 'getClaims',
          states: {
            idle: {
              on: {
                EXPIRED: '#auth.authenticating.redirectToIdP',
                SIGN_OUT: '#auth.authenticating.redirectToIdP',
                DEALER_SELECT: 'dealerSelection',
              },
            },
            getClaims: {
              invoke: {
                id: 'getClaims',
                src: 'getClaims',
                onDone: {
                  target: 'checkSuperAdmin',
                  actions: assign((_, { data }) => data),
                },
                onError: {
                  actions: 'logError',
                },
              },
            },
            checkSuperAdmin: {
              entry: ['isSuperAdmin'],
              always: [
                {
                  target: 'idle',
                  cond: ({ isSuperAdmin }) => isSuperAdmin,
                },
                {
                  target: 'checkDealerCount',
                },
              ],
            },
            checkDealerCount: {
              entry: ['updateDealerCount', 'setDealerIdFromQS'],
              always: [
                {
                  target: 'dealerSelection',
                  cond: ({ dealerCount, dealerId }) =>
                    dealerCount > 1 && !dealerId,
                },
                {
                  target: 'idle',
                  cond: ({ dealerId }) => dealerId > 0,
                  actions: ['updateMakeId'],
                },
                {
                  target: 'idle',
                  cond: ({ dealerCount, dealerId }) =>
                    dealerCount === 1 && !dealerId,
                  actions: ['updateDealerId', 'updateMakeId'],
                },
              ],
            },
            dealerSelection: {
              on: {
                CLOSE_DEALER_SELECT: [
                  {
                    target: 'idle',
                    cond: ({ dealerId }) => !!dealerId,
                  },
                  {
                    target: 'dealerSelection',
                  },
                ],
                DEALER_SELECTED: {
                  target: 'idle',
                  actions: [
                    assign({ dealerId: (_, { dealerId }) => dealerId }),
                    'updateMakeId',
                  ],
                },
              },
            },
          },
        },
        failed: {
          entry: ['logError'],
        },
      },
    },
    {
      actions: {
        logError: (_, event) => error(event.data),
        redirectToIdP: ({ authClient }) => authClient.loginWithRedirect(),
        // eslint-disable-next-line no-console
        logContext: (context, event) => console.log(context, event),
        isSuperAdmin: assign({
          isSuperAdmin: ({ claims }) =>
            claims &&
            claims['https://connect-auto/ims/user_management']
              .user_management[0] === '*',
        }),
        setDealerIdFromQS: assign({
          dealerId: ({ claims }) => {
            const search = new URLSearchParams(window.location.search);
            const dealerId = search.get('dealerId');
            return (
              (claims[
                'https://connect-auto/ims/user_management'
                // can't assume the type of the vaues in the array
                // as long as people can manually add dealer admins in Auth0,
                // so need to make a loose equality check for the time being
                // eslint-disable-next-line eqeqeq
              ].dealerships.find(id => id == dealerId) &&
                dealerId) ||
              0
            );
          },
        }),
        updateDealerCount: assign({
          dealerCount: ({ claims }) =>
            claims &&
            claims['https://connect-auto/ims/user_management'].dealerships
              .length,
        }),
        updateDealerId: assign({
          dealerId: ({ claims }) =>
            claims &&
            claims['https://connect-auto/ims/user_management'].dealerships[0],
        }),
        updateMakeId: assign({
          makeId: ({ dealerId, dealerships }) =>
            dealerId && dealerships && dealerships[dealerId].brand,
        }),
      },
      services: {
        handleRedirect: async ({ authClient, loginProps }) => {
          await authClient.handleRedirectCallback();
          window.history.replaceState(
            {},
            document.title,
            loginProps.destination,
          );
          return authClient;
        },
        checkIfRedirectFromIdP: async ({ loginProps }) =>
          loginProps.location.search.includes('code=') &&
          loginProps.location.search.includes('state='),
        createClient: async ({ clientOptions }) =>
          createAuth0Client(clientOptions),
        getToken: async ({ authClient }) => authClient.getTokenSilently(),
        getClaims: async ({ authClient }) => {
          const claims = await authClient.getIdTokenClaims();
          // this is used to get the access token instead of using gettoken
          // because get token returns a refresh token
          const { __raw: claimsEncoded } = claims;
          const { global } = window.config;

          axios.defaults.headers.common.Authorization = `Bearer ${claimsEncoded}`;
          return {
            claims,
            dealerships: await getDealerships(
              global.staffAreaGql,
              claims['https://connect-auto/ims/brands'],
              claims['https://connect-auto/ims/dealerships'],
              claims['https://connect-auto/ims/user_management'].dealerships[0],
            ),
          };
        },
      },
      guards: {
        isTrue: (_, event) => event.data === true,
      },
    },
  );

export { createAuthMachine };
