// @flow
import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { theme } from '../theme';
import { Dealers } from '../components/Dealers';
import { Footer } from '../components/Footer';
import { useAuthState, useUserState } from '../auth';

const GlobalStyle = createGlobalStyle`
  body {
    background-image: url(${theme.dealerSelect.bgImage});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  overflow-y: scroll;
  height: 100vh;
`;

const Body = styled.div`
  min-width: 350px;
  max-width: 480px;
  display: flex;
  flex-direction: column;
  margin: auto;
`;

const Header = styled.div`
  position: relative;
  border-radius: 8px 8px 0 0;
  background-color: #37526b;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 134px;
`;

const Logo = styled.img`
  display: block;
  margin: 0 auto;
`;

const Title = styled.div`
  height: 26px;
  width: 100%;
  margin-top: 18px;
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 26px;
  text-align: center;
`;

const Close = styled.button`
  position: absolute;
  right: 10px;
  top: 10px;
  height: 38px;
  width: 84px;
  cursor: pointer;
  background-color: #37526b;
  box-sizing: border-box;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 4px;
  color: #ffffff;
  font-size: ${theme.nav.fontSize};
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 20px;
  text-align: center;
  &:hover {
    background-color: #2d465e;
  }
`;

const Main = styled.main`
  background-color: #ffffff;
  border-radius: 0 0 8px 8px;
  display: flex;
  width: 100%;
  padding: 2px;
`;

const DealerSelect = () => {
  const { dealerships } = useUserState();
  const { send, context } = useAuthState();

  return (
    <>
      <GlobalStyle />
      <Container>
        <Body>
          <Header>
            {!!context.dealerId && (
              <Close onClick={() => send('CLOSE_DEALER_SELECT')}>Close</Close>
            )}
            <Logo src={theme.dealerSelect.logo} />
            <Title>Select dealership</Title>
          </Header>
          <Main>
            <Dealers dealers={dealerships} theme={theme.dealerSelect} />
          </Main>
        </Body>
        <Footer theme={theme.footer.dark} />
      </Container>
    </>
  );
};

export { DealerSelect };
