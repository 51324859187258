/* eslint-disable */
// @flow
import { css } from 'styled-components';
import { useWindowWidth } from '@react-hook/window-size';

type screenSizeQueries = {
  small: css,
  medium: css,
  large: css,
  extraLarge: css,
};

const screenSizeOptions = {
  small: 576,
  medium: 769,
  large: 992,
  extraLarge: 1200,
  xxl: 1400,
};

const useWindowSize = () => {
  const windowWidth = useWindowWidth();

  return Object.keys(screenSizeOptions).reduce((acc, size) =>
    windowWidth > screenSizeOptions[size] ? size : acc,
  );
};

const generateMediaQueries = (widthType: 'min' | 'max') =>
  Object.keys(screenSizeOptions).reduce((acc, label) => {
    acc[label] = (...args: []) => css`
      @media screen and (${widthType}-width: ${screenSizeOptions[label]}px) {
        ${css(...args)};
      }
    `;
    return acc;
  }, {});

const media: { min: screenSizeQueries, max: screenSizeQueries } = {
  min: generateMediaQueries('min'),
  max: generateMediaQueries('max'),
};

const theme: {
  header: Object,
  footer: Object,
  dealerSelect: Object,
  nav: Object,
} = {
  header: {
    height: '65px',
    bgImage:
      'https://res.cloudinary.com/motortrak/image/upload/StaffArea/bg.jpg',
  },
  footer: {
    light: {
      bgColor: '#f0f3f6',
      color: '#242424',
      height: '26px',
      social: {
        email:
          'https://res.cloudinary.com/motortrak/image/upload/v1596035393/StaffArea/Social/social-email.svg',
        linkedIn:
          'https://res.cloudinary.com/motortrak/image/upload/v1596035393/StaffArea/Social/social-linkedin.svg',
      },
    },
    dark: {
      bgColor: '#283643',
      color: '#ffffff',
      height: '26px',
      social: {
        email:
          'https://res.cloudinary.com/motortrak/image/upload/v1597650808/StaffArea/Social/social-email-white.svg',
        linkedIn:
          'https://res.cloudinary.com/motortrak/image/upload/v1597650808/StaffArea/Social/social-linkedin-white.svg',
      },
    },
  },
  nav: {
    fontSize: '12px',
  },
  dealerSelect: {
    bgImage:
      'https://res.cloudinary.com/motortrak/image/upload/StaffArea/bg.jpg',
    logo:
      'https://res.cloudinary.com/motortrak/image/upload/v1597411250/StaffArea/icon_CAlogo.svg',
    arrowIcon:
      'https://res.cloudinary.com/motortrak/image/upload/v1597412182/StaffArea/arrow-block-right-light.svg',
  },
};

export { theme, media, screenSizeOptions, useWindowSize };
