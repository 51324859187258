// the data that is returned from the graphql is set as strings instead of integers
/* eslint-disable eqeqeq */
// @flow
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import OutsideClickHandler from 'react-outside-click-handler';
import { useTranslation } from 'react-i18next';
import { FormField, Checkbox } from '.';
import IconArrow from '../../assets/icon_arrow.svg';

const MultiSelect = styled.div`
  color: #2f3031;
  font-size: 13px;
  border: none;
  min-width: 175px;
  min-height: 30px;
  height: 100%;
  width: 100%;
`;

const SelectBox = styled.div`
  height: 100%;
  min-height: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 5px 10px;
  justify-content: space-between;
`;

const CheckboxContainer = styled.div`
  position: relative;
`;

const Checkboxes = styled.div`
  display: ${props => (props.expanded ? 'flex' : 'none')};
  position: absolute;
  z-index: 999;
  padding: 0px 6px 0px 6px;
  height: 151px;
  border: 1px solid #e4e4e4;
  border-radius: 2px;
  background-color: #ffffff;
  flex-direction: column;
  margin-top: 5px;
  margin-bottom: 20px;
  overflow: auto;
  width: 100%;
`;

const Option = styled.div`
  display: flex;
  flex-direction: row;
  padding: 9px;
  border-bottom: 0.5px solid #979797;
  align-items: center;
`;

const Label = styled.span`
  font-size: 11px;
  display: flex;
  margin-left: 9px;
`;

const DisplayValue = styled.span`
  display: flex;
`;

const DropDownIcon = styled.div`
  display: flex;
  height: 7px;
  width: 9px;
  min-width: 9px;
  background-image: url(${IconArrow});
  background-repeat: no-repeat;
`;

type Props = {
  keyValue: string,
  value: Option,
  onChange: Object => void,
  disabled: boolean,
  options: Option[],
  label: string,
  required: boolean,
  children: Node,
  error?: string,
  value: string,
  placeholder?: string,
  defaultValue?: Option,
};

const MultiSelectField = ({
  label,
  required,
  options = [],
  defaultValue = [],
  error,
  onChange,
}: Props) => {
  const [expanded, setExpanded] = useState(false);
  const [selected, setSelected] = useState([]);
  const { t } = useTranslation();

  const handleChange = item => {
    const isChecked = selected.some(e => e.key == item.key);
    if (!isChecked) {
      setSelected([...selected, item]);
    } else {
      setSelected(selected.filter(f => f.key != item.key));
    }
  };

  const renderValue = () => {
    if (!selected.length) return t('select');
    const value = selected
      .reduce((prev, curr) => [...prev, curr.label], [])
      .join(', ');
    return value.length > 500 ? `${value.substring(0, 500)}...` : value;
  };

  useEffect(() => {
    if (defaultValue.length) setSelected(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    onChange({ selected });
  }, [selected, onChange]);

  return (
    <OutsideClickHandler onOutsideClick={() => setExpanded(false)}>
      <FormField label={label} required={required} error={error}>
        <MultiSelect>
          <SelectBox
            onClick={() => setExpanded(!expanded)}
            data-testid="dropdown"
          >
            <DisplayValue>{renderValue()}</DisplayValue>
            <DropDownIcon />
          </SelectBox>
          <CheckboxContainer>
            <Checkboxes expanded={expanded}>
              {options.map((o, i) => {
                return (
                  <Option key={o.key}>
                    <Checkbox
                      isChecked={selected.some(e => e.key == o.key)}
                      onClick={() => handleChange(o)}
                      data-testid={`option-${i}`}
                    />
                    <Label>{o.label}</Label>
                  </Option>
                );
              })}
            </Checkboxes>
          </CheckboxContainer>
        </MultiSelect>
      </FormField>
    </OutsideClickHandler>
  );
};

export { MultiSelectField };
