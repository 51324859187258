// @flow
import React from 'react';
import { Router } from '@reach/router';
import { Layout } from './Layout';
import { List as ListAdmins } from './Administrators/List';
import { List as ListDealers } from './Dealers/List';
import { Add as AddAdmin } from './Administrators/Add';
import { Add as AddDealer } from './Dealers/Add';
import { Amend as AmendAdmin } from './Administrators/Amend';
import { Amend as AmendDealer } from './Dealers/Amend';
import { useAuthState } from '../auth';

const ListUsers = ({ isSuperAdmin, dealerId }) =>
  isSuperAdmin ? <ListAdmins /> : <ListDealers dealerId={dealerId} />;

const AddUser = ({ isSuperAdmin, dealerId }) =>
  isSuperAdmin ? <AddAdmin /> : <AddDealer dealerId={dealerId} />;

const AuthenticatedRoutes = () => {
  const {
    context: { isSuperAdmin, dealerId },
  } = useAuthState();

  return (
    <Router data-testid="authenticated-routes">
      <Layout path="/">
        <ListUsers isSuperAdmin={isSuperAdmin} dealerId={dealerId} path="/" />
        <AddUser
          isSuperAdmin={isSuperAdmin}
          dealerId={dealerId}
          path="add-user"
        />
        {isSuperAdmin ? (
          <AmendAdmin email="" path="amend-user/:email" />
        ) : (
          <AmendDealer dealerId={dealerId} email="" path="amend-user/:email" />
        )}
      </Layout>
    </Router>
  );
};

export { AuthenticatedRoutes };
