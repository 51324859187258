// @flow
import React from 'react';
import type { Node } from 'react';

import styled from 'styled-components';

const Action = styled.div`
  background-color: #283643;
  display: flex;
  width: 100%;
  justify-content: center;
  flex: none;
  min-height: 114px;
`;

const SubHeader = ({ children }: { children: Node }) => (
  <Action>{children}</Action>
);

export { SubHeader };
