// @flow
import { useContext } from 'react';
import { useMachine } from '@xstate/react';
import { createAuthMachine } from './authMachine';
import { AuthContext, UserContext } from '.';
import { objectFilter } from '../utils';
import type { ClientOptions, Logger } from './types';

const useAuthState = () => {
  return useContext(AuthContext);
};

const useAuthMachine = (clientOptions: ClientOptions, error: Logger) => {
  const [current, send] = useMachine(createAuthMachine(clientOptions, error));
  const { context } = current;
  const state = current.value;

  return { current, send, state, context };
};

const useIsAuthenticated = () => {
  return useContext(AuthContext).matches('authenticated.idle');
};

const useUserState = () => {
  const { dealerId, makeId, claims, dealerships } = useContext(UserContext);
  const getClaim = id => {
    const namespace = 'https://connect-auto/ims/';
    return (claims && claims[namespace + id]) || '';
  };

  const brands = getClaim('brands');
  const userManagement = getClaim('user_management');

  const usersDealerships =
    userManagement.dealerships[0] !== '*'
      ? objectFilter(dealerships, dealer =>
          // can't assume the type of the vaues in the array
          // as long as people can manually add dealer admins in Auth0,
          // so need to make a loose equality check for the time being
          // eslint-disable-next-line eqeqeq
          userManagement.dealerships.find(id => id == dealer.id),
        )
      : dealerships;

  return {
    dealerId,
    makeId,
    brands,
    dealerships: usersDealerships,
    userManagement,
    logo: brands && makeId && brands[makeId].logo,
    dealerName: dealerships && dealerId && dealerships[dealerId].name,
  };
};

export { useAuthState, useAuthMachine, useIsAuthenticated, useUserState };
