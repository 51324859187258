import React, { useContext } from 'react';
import { useModal } from 'react-modal-hook';
import styled from 'styled-components';
import useAxios from 'axios-hooks';
import { Link as RouterLink } from '@reach/router';
import { store } from 'react-notifications-component';
import { ConfirmDialog, Notification } from '../../components';
import { error } from '../../log';
import { ConfigContext } from '../../ConfigProvider';
import IconSuccess from '../../assets/notification_success.svg';
import IconError from '../../assets/notification_error.svg';
import { ReactComponent as IconRemove } from '../../assets/icon_remove.svg';
import IconEdit from '../../assets/icon_edit.svg';

const ActionButton = styled.button`
  color: #000000;
  text-decoration: none;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  height: 25px;
  width: 75px;
  border: 1px solid #e2e2e2;
  border-radius: 2px;
  background-color: #ffffff;
  cursor: pointer;
  &:hover {
    background-color: #f7f7f7;
  }
  align-items: center;
  justify-content: space-around;
  display: flex;
  padding-left: 5px;
  justify-items: center;
  align-items: center;
`;

const ActionGroup = styled.span`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ActionIcon = styled.img`
  cursor: pointer;
  height: 15px;
`;

const UserActions = ({ user, onChange, dealerId }) => {
  const config = useContext(ConfigContext);

  const [{ error: getError }, executeDelete] = useAxios(
    {
      url: `${config.api.domain}${config.api.dealerships}${dealerId}/user/${
        user.email
      }`,
      method: 'DELETE',
    },
    { manual: true },
  );

  const notification = {
    insert: 'top',
    container: 'top-right',
    animationIn: ['animated', 'fadeIn'],
    animationOut: ['animated', 'fadeOut'],
    width: 390,
    dismiss: {
      duration: 5000,
    },
  };

  const onSuccess = () => {
    store.addNotification({
      ...notification,
      content: Notification({
        color: '#4ac57e',
        title: 'User removed',
        description: 'This user will no longer have access to the system.',
        icon: IconSuccess,
      }),
    });
  };

  const onError = () =>
    store.addNotification({
      ...notification,
      content: Notification({
        color: '#4ac57e',
        title: 'Something went wrong',
        description:
          'We were unable to delete this user. Please check your connection and try again.',
        icon: IconError,
      }),
    });

  async function deleteUser() {
    try {
      await executeDelete();
      onSuccess();
      // eslint-disable-next-line no-use-before-define
      hideConfirmModal();
      onChange();
    } catch (err) {
      onError();
      error(getError);
    }
  }
  const [showConfirmModal, hideConfirmModal] = useModal(
    ({ in: open, onExited }) => (
      <ConfirmDialog
        open={open}
        onExited={onExited}
        title={`Are you sure you want to remove ${user.email}`}
        description={`This action is irreversible. Deleting this user will permantently remove this account and disallow them access to the system. 

        Are you sure you wish to remove this user?`}
        confirmLabel="Delete user"
        onConfirm={deleteUser}
        DialogIcon={IconRemove}
        ConfirmIcon={IconRemove}
        onCancel={hideConfirmModal}
      />
    ),
    [user],
  );

  return (
    <ActionGroup>
      {user.role !== 'Dealer Admin' && (
        <>
          <ActionButton as={RouterLink} to={`/amend-user/${user.email}`}>
            Amend
            <ActionIcon src={IconEdit} />
          </ActionButton>
          <ActionButton onClick={showConfirmModal}>
            Delete
            <IconRemove />
          </ActionButton>
        </>
      )}
    </ActionGroup>
  );
};
export { UserActions };
