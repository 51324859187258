// @flow
import React from 'react';
import { useAuthState } from '../auth';
import { DealerSelect } from './DealerSelect';
import { AuthenticatedRoutes } from './AuthenticatedRoutes';
import { UnauthenticatedRoutes } from './UnauthenticatedRoutes';

const Site = () => {
  const {
    state: { authenticated },
  } = useAuthState();

  switch (authenticated) {
    case 'dealerSelection':
      return <DealerSelect />;
    case 'idle':
      return <AuthenticatedRoutes />;
    default:
      return <UnauthenticatedRoutes />;
  }
};

export { Site };
