// @flow
import React from 'react';
import styled from 'styled-components';
import { useUserState, useAuthState } from '../auth';
import { ChangeDealer } from './ChangeDealer';
import { theme } from '../theme';

const Container = styled.div`
  display: flex;
  background-image: url(${theme.header.bgImage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  flex-shrink: 0;
  width: 100%;
  min-height: ${theme.header.height};
  justify-content: space-between;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`;

const Title = styled.div`
  color: #ffffff;
  font-size: 20px;
  line-height: 29px;
  display: flex;
  margin-left: 31px;
  margin-right: 20px;
  align-items: center;
`;

const LogoContainer = styled.div`
  margin-right: 29px;
  align-items: center;
  display: flex;
`;

const Logo = styled.img`
  height: 30px;
`;

const Header = () => {
  const { logo, dealerName } = useUserState();
  const { context } = useAuthState();
  return (
    <Container role="banner">
      <TitleContainer>
        <Title data-testid="dealer-header">
          {context.isSuperAdmin ? 'Connect-Auto Administration' : dealerName}
        </Title>
        {context.dealerCount > 1 && !context.isSuperAdmin && <ChangeDealer />}
      </TitleContainer>
      <LogoContainer>
        {context.isSuperAdmin ? (
          <Logo
            src="https://ims-static.dev.mtkip.com/ims/icon_CAlogo.svg"
            alt="Connect-Auto-logo"
            data-testid="header-logo"
          />
        ) : (
          <Logo
            src={logo}
            alt={`${dealerName}-logo`}
            data-testid="header-logo"
          />
        )}
      </LogoContainer>
    </Container>
  );
};

export { Header };
