import React from 'react';
import styled from 'styled-components';
import { Dialog } from '@reach/dialog';
import '@reach/dialog/styles.css';
import { PrimaryButton, SecondaryButton } from '.';
import IconClose from '../assets/notification_close.svg';

const DialogContainer = styled(Dialog)`
  height: 250px;
  width: 407px;
  background-color: #37526b;
  padding: 0px;
  flex-direction: row;
  justify-content: space-evenly;
  display: flex;
`;

const CloseButton = styled.div`
  height: 36px;
  width: 36px;
  cursor: pointer;
  justify-items: center;
  align-items: center;
}`;

const DialogContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 75%;
`;

const DialogContentContainer = styled.div`
  display: flex;
  padding: 28px 0px 28px 0px;
  flex-direction: row;
  justify-content: space-evenly;
`;

const Title = styled.div`
  color: #ffffff;
  font-size: 15px;
  font-weight: bold;
  line-height: 22px;
  position: relative;
`;

const Description = styled.div`
  color: #ffffff;
  line-height: 16px;
  font-size: 12px;
  white-space: pre-line;
`;

const ActionContainer = styled.div`
  color: #ffffff;
  line-height: 16px;
  display: flex;
  justify-content: space-between;
`;

const PrimaryActionButton = styled(PrimaryButton)`
  background-color: #ff6767;
  border-radius: 4px;
  color: #ffffff;
  font-size: 12px;
  font-weight: 100;
  width: 127px;
  justify-content: space-evenly;
`;

const SecondaryActionButton = styled(SecondaryButton)`
  border-radius: 4px;
  color: #ffffff;
  font-size: 12px;
  width: 127px;
`;
const CloseIcon = styled.img`
  box-sizing: border-box;
  height: 36px;
`;

const DialogIconWrap = styled.div`
  border-radius: 25px;
  color: #ff6767;
  border: 2px solid #ff6767;
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ConfirmDialog = ({
  title,
  confirmLabel,
  ConfirmIcon,
  DialogIcon,
  description,
  onConfirm,
  onCancel,
}) => {
  return (
    <DialogContainer>
      <DialogContentContainer>
        {DialogIcon && (
          <DialogIconWrap>
            <DialogIcon />
          </DialogIconWrap>
        )}
        <DialogContent>
          <Title>{title}</Title>
          <Description>{description}</Description>
          <ActionContainer>
            <PrimaryActionButton onClick={onConfirm}>
              {ConfirmIcon && <ConfirmIcon />}
              {confirmLabel}
            </PrimaryActionButton>
            <SecondaryActionButton onClick={onCancel}>
              Cancel
            </SecondaryActionButton>
          </ActionContainer>
        </DialogContent>
      </DialogContentContainer>
      <CloseButton onClick={onCancel}>
        <CloseIcon src={IconClose} />
      </CloseButton>
    </DialogContainer>
  );
};

export { ConfirmDialog };
