// @flow
import React from 'react';
import styled from 'styled-components';
import type { Node } from 'react';
import { theme, media } from '../theme';
import { useAuthState } from '../auth';

import { Header } from '../components/Header';
import { Footer } from '../components/Footer';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  color: white;
  height: 100%;
  font-size: 1.2em;
  width: 100%;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  flex: none;
  ${media.min.small`
    height: 100%;
    flex-direction: column-reverse;
  `};
`;

const Main = styled.main`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const Layout = ({ children }: { children: Node }) => {
  const {
    context: { isSuperAdmin },
  } = useAuthState();
  return (
    <Container>
      <Header role="banner">Header</Header>
      <Body>
        <Main>{children}</Main>
      </Body>
      {!isSuperAdmin && <Footer theme={theme.footer.light} />}
    </Container>
  );
};

export { Layout };
