// @flow
import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import useAxios from 'axios-hooks';
import formatDistance from 'date-fns/formatDistance';
import { Link as RouterLink, navigate } from '@reach/router';
import { useTranslation } from 'react-i18next';
import { error } from '../../log';
import { Table } from '../../components/Table';
import {
  SubHeader,
  PrimaryButton,
  SecondaryButton,
  Spinner,
} from '../../components';
import { ConfigContext } from '../../ConfigProvider';
import BackArrow from '../../assets/arrow-block-left-light.svg';
import SpinnerIcon from '../../assets/spinner-large-blue.svg';
import { UserActions } from './UserActions';
import { useUserState } from '../../auth';

const Container = styled.div`
  min-height: 100vh;
  height: 100%;
  background-color: #e1e7ed;
  flex-direction: column;
  width: 100%;
  color: black;
  align-items: center;
  display: flex;
`;

const ContentContainer = styled.div`
  max-width: 1182px;
  width: 80%;
  display: flex;
  justify-content: space-between;
`;

const TableContainer = styled(ContentContainer)`
  margin-top: -36px;
  height: 100%;
`;

const SubHeaderContainer = styled(SubHeader)`
  justify-content: center;
  justify-content: flex-end;
  height: 114px;
`;

const PrimaryActionButton = styled(PrimaryButton)`
  margin-top: 20px;
`;
const SecondaryActionButton = styled(SecondaryButton)`
  margin-top: 20px;
  color: #ffffff;
  justify-content: space-around;
`;

const MandatoryColumn = styled.span`
  font-weight: 600;
`;

const ActionIcon = styled.img`
  cursor: pointer;
  height: 15px;
`;

const DealersList = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const EmailColumn = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const List = () => {
  const config = useContext(ConfigContext);
  const { dealerships } = useUserState();
  const [{ data, loading, error: getError }, refetch] = useAxios(
    `${config.api.domain}${config.api.dealerAdmins}`,
  );

  useEffect(() => {
    if (!loading) refetch();
    // eslint-disable-next-line
  }, []);
  const { t } = useTranslation();

  if (getError) error(getError);

  const columns = [
    {
      id: 'index',
      Header: '',
      maxWidth: 25,
      accessor: 'index',
      Cell: ({ cell }) => (
        <MandatoryColumn>{cell.row.position}</MandatoryColumn>
      ),
    },
    {
      id: 'firstName',
      Header: t('firstName'),
      accessor: 'firstName',
      width: 100,
      Cell: ({ value }) => <MandatoryColumn>{value}</MandatoryColumn>,
    },
    {
      id: 'lastName',
      Header: t('lastName'),
      accessor: 'lastName',
      width: 100,
      Cell: ({ value }) => <MandatoryColumn>{value}</MandatoryColumn>,
    },
    {
      id: 'email',
      Header: t('email'),
      accessor: 'email',
      minWidth: 200,
      hiddenScreenSizes: ['small', 'medium'],
      Cell: ({ value }) => <EmailColumn>{value}</EmailColumn>,
    },
    {
      id: 'createdAt',
      Header: t('accountCreated'),
      accessor: 'createdAt',
      hiddenScreenSizes: ['small', 'medium', 'large'],
      Cell: ({ value }) =>
        formatDistance(new Date(value), new Date(), {
          addSuffix: true,
        }),
    },
    {
      id: 'lastLogin',
      Header: t('lastLogin'),
      accessor: 'lastLogin',
      hiddenScreenSizes: ['small', 'medium', 'large'],
      Cell: ({ value }) =>
        value
          ? formatDistance(new Date(value), new Date(), {
              addSuffix: true,
            })
          : 'never',
    },
    {
      id: 'dealerships',
      accessor: 'dealerships',
      Header: t('permissionLevel'),
      width: 200,
      hiddenScreenSizes: ['small', 'medium'],
      Cell: ({ value }) => (
        <DealersList>
          {value
            .map(
              dealerId =>
                dealerships[dealerId.id] && dealerships[dealerId.id].name,
            )
            .join(', ')}
        </DealersList>
      ),
    },
    {
      id: 'actions',
      Header: t('actions'),
      accessor: 'role',
      width: 160,
      hiddenScreenSizes: ['small', 'medium', 'large'],
      Cell: ({ row: { original: user } }) => (
        <UserActions user={user} onChange={() => refetch()} />
      ),
    },
  ];

  return (
    <Container data-testid="administrators">
      <SubHeaderContainer>
        <ContentContainer>
          <SecondaryActionButton onClick={() => navigate(config.staffAreaUrl)}>
            <ActionIcon src={BackArrow} alt={t('backToIMSArrow')} />
            {t('backToIMS')}
          </SecondaryActionButton>
          <PrimaryActionButton as={RouterLink} to="/add-user">
            {t('AddNewUser')}
          </PrimaryActionButton>
        </ContentContainer>
      </SubHeaderContainer>
      {loading ? (
        <Spinner icon={SpinnerIcon} />
      ) : (
        <TableContainer>
          <Table columns={columns} data={(data && data.contents) || []} />
        </TableContainer>
      )}
    </Container>
  );
};

export { List };
