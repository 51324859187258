// @flow
import React from 'react';
import styled from 'styled-components';
import { FormField } from '.';
import IconArrow from '../../assets/icon_arrow.svg';
import IconLock from '../../assets/icon_lock.svg';

const Select = styled.select`
  display: block;
  border: none;
  outline: none;
  box-shadow: none;
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  min-height: 40px;
  padding: 11px 9px;
  margin: 0;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background: none;
  background-image: ${({ disabled }: { disabled: boolean }) =>
    disabled ? `url(${IconLock})` : `url(${IconArrow})`};
  background-repeat: no-repeat, repeat;
  background-position: right 9px top 50%, 0 0;
  background-size: 0.65em auto, 100%;
`;

const Option = styled.option`
  font-size: 14px;
`;

type Props = {
  label: string,
  required: boolean,
  type: string,
  error?: string,
  disabled: boolean,
  placeholder: string,
  selected: Object,
  options: Array<Object>,
  reference: any,
  name: string,
  options: Array<Object>,
  selected: string,
};

const SelectField = ({
  label,
  required,
  options,
  selected,
  error,
  disabled,
  placeholder,
  reference,
  name,
}: Props) => (
  <FormField
    label={label}
    required={required}
    error={error}
    disabled={disabled}
  >
    <Select
      placeholder={placeholder}
      ref={reference}
      name={name}
      defaultValue={selected}
      error={error}
      data-testid={name}
    >
      {options.map(o => (
        <Option value={o.id} key={o.id}>
          {o.name}
        </Option>
      ))}
    </Select>
  </FormField>
);

export { SelectField };
