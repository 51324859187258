// @flow
import React, { createContext } from 'react';
import type { Node } from 'react';

type AuthState = 'initializing' | 'authenticating' | 'authenticated' | 'failed';

const AuthContext = createContext<?AuthState>();

const AuthProvider = ({
  children,
  state,
}: {
  children: Node,
  state: AuthState,
}) => <AuthContext.Provider value={state}>{children}</AuthContext.Provider>;

export { AuthProvider, AuthContext };
