import axios from 'axios';

import { error } from '../log';

const getDealerships = async (
  url,
  brands,
  dealerIds,
  userManagementDealers,
) => {
  try {
    const brandIds = Object.keys(brands);

    const { data } = await axios.post(url, {
      query: `query getFranchiseDealers {
        vImsFranchiseDealers(filter: {franchiseId: {in: [${brandIds.map(
          id => `"${id}",`,
        )}]}}) {
          nodes {
            dealerId
            dealerName
            franchiseId
          }
        }
      }`,
    });

    const usersDealerships =
      userManagementDealers === '*'
        ? data.data.vImsFranchiseDealers.nodes
        : data.data.vImsFranchiseDealers.nodes.filter(dealer =>
            dealerIds.includes(parseInt(dealer.dealerId, 10)),
          );

    return usersDealerships.reduce(
      (obj, item) => ({
        ...obj,
        [item.dealerId]: {
          id: item.dealerId,
          name: item.dealerName,
          brand: item.franchiseId,
        },
      }),
      {},
    );
  } catch (err) {
    return error(err);
  }
};

export { getDealerships };
