// @flow
import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import useAxios from 'axios-hooks';
import formatDistance from 'date-fns/formatDistance';
import { Link as RouterLink, navigate } from '@reach/router';
import { useTranslation } from 'react-i18next';
import { error } from '../../log';
import { Table } from '../../components/Table';
import { UserActions } from './UserActions';
import {
  SubHeader,
  PrimaryButton,
  SecondaryButton,
  Spinner,
} from '../../components';
import { StatusLabel } from '../../components/StatusLabel';
import { ConfigContext } from '../../ConfigProvider';
import BackArrow from '../../assets/arrow-block-left-light.svg';
import SpinnerIcon from '../../assets/spinner-large-blue.svg';

const Container = styled.div`
  background-color: #e1e7ed;
  flex-direction: column;
  width: 100%;
  color: black;
  min-height: 100vh;
  align-items: center;
  display: flex;
`;

const ContentContainer = styled.div`
  max-width: 1182px;
  width: 80%;
  display: flex;
  justify-content: space-between;
  height: 114px;
`;

const TableContainer = styled.div`
  max-width: 1182px;
  width: 80%;
  display: flex;
  justify-content: space-between;
  margin-top: -36px;
  height: 100%;
  padding-bottom: 10px;
`;

const SubHeaderContainer = styled(SubHeader)`
  justify-content: center;
  justify-content: flex-end;
  margin-top: 20px;
  min-height: 114px;
`;

const PrimaryActionButton = styled(PrimaryButton)`
  margin-top: 20px;
`;
const SecondaryActionButton = styled(SecondaryButton)`
  margin-top: 20px;
  color: #ffffff;
  justify-content: space-around;
`;
const MandatoryColumn = styled.span`
  font-weight: 600;
`;

const ActionIcon = styled.img`
  cursor: pointer;
  height: 15px;
`;

const EmailColumn = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const List = ({ dealerId }: { dealerId: string }) => {
  const config = useContext(ConfigContext);
  const [{ data, loading, error: getError }, refetch] = useAxios(
    `${config.api.domain}${config.api.dealerships}${dealerId}`,
  );
  const { t } = useTranslation();

  useEffect(() => {
    if (!loading) refetch();
    // eslint-disable-next-line
  }, []);

  if (getError) error(getError);

  const columns = [
    {
      id: 'index',
      Header: '',
      maxWidth: 25,
      accessor: 'index',
      Cell: ({ cell }) => (
        <MandatoryColumn>{cell.row.position}</MandatoryColumn>
      ),
    },
    {
      id: 'firstName',
      Header: t('firstName'),
      accessor: 'firstName',
      width: 100,
      Cell: ({ value }) => <MandatoryColumn>{value}</MandatoryColumn>,
    },
    {
      id: 'lastName',
      Header: t('lastName'),
      accessor: 'lastName',
      width: 100,
    },
    {
      id: 'email',
      Header: t('email'),
      accessor: 'email',
      minWidth: 200,
      hiddenScreenSizes: ['small', 'medium'],
      Cell: ({ value }) => <EmailColumn>{value}</EmailColumn>,
    },
    {
      id: 'createdAt',
      Header: t('accountCreated'),
      accessor: 'createdAt',
      hiddenScreenSizes: ['small', 'medium', 'large'],
      Cell: ({ value }) =>
        formatDistance(new Date(value), new Date(), {
          addSuffix: true,
        }),
    },
    {
      id: 'lastLogin',
      Header: t('lastLogin'),
      accessor: 'lastLogin',
      hiddenScreenSizes: ['small', 'medium', 'large'],
      Cell: ({ value }) =>
        value
          ? formatDistance(new Date(value), new Date(), {
              addSuffix: true,
            })
          : 'never',
    },
    {
      id: 'role',
      accessor: 'dealerships',
      Header: t('permissionLevel'),
      maxWidth: 95,
      hiddenScreenSizes: ['small', 'medium'],
      Cell: ({ value }) => {
        const { role } = value.find(({ id }) => id === Number(dealerId));
        return (
          <StatusLabel
            label={role === 'Dealer Admin' ? 'Admin' : 'Dealer'}
            colour={role === 'Dealer Admin' ? '#4AC57E' : '#007AFF'}
          />
        );
      },
    },
    {
      id: 'actions',
      Header: t('actions'),
      accessor: 'role',
      width: 160,
      hiddenScreenSizes: ['small', 'medium', 'large'],
      Cell: ({ row: { original: user } }) => (
        <UserActions
          user={user}
          onChange={() => refetch()}
          dealerId={dealerId}
        />
      ),
    },
  ];

  return (
    <Container data-testid="list-dealers">
      <SubHeaderContainer>
        <ContentContainer>
          <SecondaryActionButton
            onClick={() =>
              navigate(`${config.staffAreaUrl}?dealerId=${dealerId}`)
            }
          >
            <ActionIcon src={BackArrow} alt={t('backToIMSArrow')} />
            {t('backToIMS')}
          </SecondaryActionButton>
          <PrimaryActionButton as={RouterLink} to="/add-user">
            {t('AddNewUser')}
          </PrimaryActionButton>
        </ContentContainer>
      </SubHeaderContainer>
      {loading ? (
        <Spinner icon={SpinnerIcon} />
      ) : (
        <TableContainer>
          <Table columns={columns} data={(data && data.contents) || []} />
        </TableContainer>
      )}
    </Container>
  );
};

export { List };
