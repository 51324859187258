// @flow
import React, { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { hasPath, isEmpty } from 'ramda';

import { InputField, AutoComplete } from '.';
import { useUserState, useAuthState } from '../../auth';

const dealersFieldName = 'assignedTo';
const brandFieldName = 'brand';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 310px;
  flex-flow: column nowrap;
`;

const Header = styled.div`
  margin-bottom: 20px;
  color: #283643;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 29px;
`;

const InputContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-evenly;
`;

const UserForm = ({
  title,
  form,
  assignedTo = [], // This contains available dealers in database
  user = {},
  disabledFields = [],
}: {
  title: string,
  form: Object,
  assignedTo: Object,
  user: Object,
  disabledFields: Array<string>,
}) => {
  const { dealerships, brands } = useUserState();
  const [userBrands, setUserBrands] = useState([]);
  const [userDealers, setUserDealers] = useState([]);
  const { register, reset, errors, setValue } = form;
  const { t } = useTranslation();
  const {
    context: { isSuperAdmin },
  } = useAuthState();

  const availableBrands = useMemo(
    () =>
      Object.values(brands).map(({ id, name }) => ({
        id,
        key: id,
        label: name,
      })),

    // eslint-disable-next-line
    [],
  );

  // Check available dealers for the user.
  const availableDealers = useMemo(() => {
    const loggedUserBrands = Object.values(brands).map(brand => brand.id);
    const loggedUserDealers = Object.values(dealerships).map(dealer => dealer);
    const allDealers = assignedTo.filter(dealer => {
      return (
        loggedUserBrands.includes(parseInt(dealer.brandId, 10)) ||
        loggedUserDealers.some(o => o.id === parseInt(dealer.key, 10))
      );
    });

    // remove dealers with brands that is not in user brands
    const filteredDealers = allDealers.filter(dealer =>
      userBrands.some(({ id }) => id === parseInt(dealer.brandId, 10)),
    );
    return filteredDealers;
    // eslint-disable-next-line
  }, [userBrands]);

  // Manage field values when user changes brands.
  useEffect(() => {
    if (isSuperAdmin && availableBrands.length) {
      register({ name: brandFieldName }, { required: true });
    }
    if (isSuperAdmin && availableDealers.length) {
      register({ name: dealersFieldName }, { required: true });
    }

    if (userBrands.length > 0) form.setValue(brandFieldName, userBrands);
    else form.setValue(brandFieldName, '');

    if (userDealers.length > 0) form.setValue(dealersFieldName, userDealers);
    else form.setValue(dealersFieldName, '');

    // eslint-disable-next-line
  }, [
    form,
    userDealers,
    availableDealers,
    register,
    availableBrands,
    userBrands,
  ]);

  // Manage field values when user is loaded from api in amend
  useEffect(() => {
    if (
      isSuperAdmin &&
      hasPath(['dealerships'], user) &&
      !isEmpty(user.dealerships)
    ) {
      setUserDealers(
        user.dealerships.map(({ id }) => ({
          id,
          key: id,
          label: dealerships[id] ? dealerships[id].name : '[dealer not found]',
          brandId: dealerships[id] ? parseInt(dealerships[id].brand, 10) : 0,
        })),
      );
    }
    if (
      isSuperAdmin &&
      hasPath(['brands'], user) &&
      !isEmpty(Object.values(user.brands))
    ) {
      setUserBrands(
        Object.values(user.brands).map(({ id, name }) => ({
          id,
          key: id,
          label: name,
        })),
      );
    }
    if (!isEmpty(user)) {
      reset(user);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Form>
      <Header>{title}</Header>
      <InputContainer>
        <InputField
          name="firstName"
          type="text"
          label={t('firstName')}
          error={errors.firstName}
          required
          disabled={disabledFields.includes('firstName')}
          reference={register({ required: t('required') })}
        />
        <InputField
          name="lastName"
          type="text"
          label={t('lastName')}
          error={errors.lastName}
          required
          disabled={disabledFields.includes('lastName')}
          reference={register({ required: t('required') })}
        />
        <InputField
          name="email"
          type="text"
          label={t('email')}
          error={errors.email}
          required
          disabled={disabledFields.includes('email')}
          reference={register({
            required: 'required',
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: t('invalidEmail'),
            },
          })}
        />
        {isSuperAdmin && availableBrands.length > 0 && (
          <AutoComplete
            suggestions={availableBrands}
            name={brandFieldName}
            label={t(brandFieldName)}
            error={errors[brandFieldName]}
            defaultValue={userBrands}
            required
            onSelection={value => {
              setUserBrands(value.selected);
              setUserDealers([]);
              setValue(brandFieldName, value.selected, {
                shouldValidate: true,
                shouldDirty: true,
              });
            }}
          />
        )}
        {availableDealers.length > 0 && (
          <AutoComplete
            suggestions={availableDealers}
            name={dealersFieldName}
            label={t('assignedToInput')}
            error={errors.assignedTo}
            defaultValue={userDealers}
            required
            onSelection={value => {
              setUserDealers(value.selected);
              setValue(dealersFieldName, value.selected, {
                shouldValidate: true,
                shouldDirty: true,
              });
            }}
          />
        )}
      </InputContainer>
    </Form>
  );
};

export { UserForm };
