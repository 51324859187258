/* eslint-disable camelcase */
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import en_gb from './en_GB/translation';
import de from './de/translation';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      order: ['path', 'localStorage'],
    },
    resources: {
      en_gb,
      de,
    },
    lng: navigator.language,
    fallbackLng: 'en_gb',
  });

// https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes
// list of iso codes used by browsers to determine language
