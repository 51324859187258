// @flow
import React, { useState, useEffect } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import styled, { css } from 'styled-components';
import { FormField } from '.';
import type { Suggestion } from '../../types/Form/autocomplete';

const Container = styled.ul`
  min-height: 40px;
  padding: 2px;
  margin: 0;
  width: 100%;
`;

const Input = styled.input`
  padding: 9px;
  min-height: 30px;
  width: 100%;
  padding: 0 9px;
  box-sizing: border-box;
  outline: none;
  box-shadow: none;
  margin: -10px 2px 0 2px;
`;

const NoSuggestions = styled.div`
  color: #999;
  padding: 0.5rem;
  font-size: 11px;
`;

const SelectedItem = styled.li`
  display: inline-flex;
  align-items: center;
  padding: 3px 5px;
  background-color: #bdc7d3;
  color: #000;
  font-size: 12px;
  margin-left: 3px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
`;

const Suggestions = styled.ul`
  border: 1px solid #999;
  border-top-width: 0;
  list-style: none;
  margin-top: 0;
  max-height: 250px;
  overflow-y: auto;
  padding-left: 0;
  width: 100%;
`;
const SuggestionItem = styled.li`
  padding: 0.5rem;
  color: ${props => (props.disabled ? '#334F3F' : '#000')};
  opacity: ${props => (props.disabled ? 0.7 : 1)};
  font-size: 13px;

  ${props =>
    !props.disabled &&
    css`
      &:hover {
        background-color: #304558;
        color: #fff;
        cursor: pointer;
        font-weight: 700;
      }
    `}

  &:not(:last-of-type) {
    border-bottom: 1px solid #999;
  }
`;

const Remove = styled.div`
  position: relative;
  height: 10px; /* this can be anything */
  width: 10px; /* ...but maintain 1:1 aspect ratio */
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 3px;
  cursor: pointer;

  &:before,
  &:after {
    position: absolute;
    content: '';
    width: 100%;
    height: 1px; /* cross thickness */
    background-color: #000;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
`;

type Props = {
  suggestions: Suggestion[],
  defaultValue: Suggestion[],
  label: string,
  required: boolean,
  error?: string,
  onSelection: Object => void,
};

const AutoComplete = (props: Props) => {
  const {
    suggestions,
    defaultValue = [],
    label,
    required = false,
    error,
    onSelection,
  } = props;
  const [filteredSuggestions, setFilteredSuggestions] = useState<Suggestion[]>(
    [],
  );
  const [selectedValues, setSelectedValues] = useState<Suggestion[]>(
    defaultValue,
  );
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [input, setInput] = useState('');

  useEffect(() => {
    setSelectedValues(defaultValue);
  }, [defaultValue, setSelectedValues]);

  const onChange = e => {
    const userInput = e.target.value;

    // Filter our suggestions that contain the user's input
    const unLinked = suggestions.filter(
      item => item.label.toLowerCase().indexOf(userInput.toLowerCase()) > -1,
    );
    setInput(userInput);
    setFilteredSuggestions(unLinked);
  };

  const onClick = (e, clickedItem: Suggestion, selectable: boolean) => {
    e.preventDefault();
    if (!selectable) return;
    const selectedItem = filteredSuggestions.find(
      i => i.label === clickedItem.label,
    );
    const selected = [...selectedValues, selectedItem || {}];
    setSelectedValues(selected);
    setInput('');
    setFilteredSuggestions([]);
    onSelection({ selected });
  };

  const removeSelectedItem = (key: number) => {
    const selected = selectedValues.filter(s => s.key !== key);
    setSelectedValues(selected);
    onSelection({ selected });
  };

  return (
    <OutsideClickHandler onOutsideClick={() => setShowSuggestions(false)}>
      <FormField label={label} required={required} error={error}>
        <Container onClick={() => setShowSuggestions(!showSuggestions)}>
          {selectedValues.map(item => (
            <SelectedItem key={item.key}>
              {item.label}
              <Remove
                onClick={e => {
                  e.stopPropagation();
                  removeSelectedItem(item.key);
                }}
              />
            </SelectedItem>
          ))}
        </Container>
      </FormField>
      {showSuggestions && (
        <>
          <Input
            type="text"
            onChange={onChange}
            value={input}
            name="suggestionTextbox"
            data-testid="suggestion-textbox"
            placeholder="Type here..."
            autoFocus
          />
          <Suggestions>
            {filteredSuggestions.length === 0 && input && (
              <NoSuggestions>
                <span>No suggestions found</span>
              </NoSuggestions>
            )}
            {filteredSuggestions.length > 0 &&
              filteredSuggestions.map(suggestion => {
                const isSelected =
                  selectedValues.find(s => s.key === suggestion.key) !==
                  undefined;
                return (
                  <SuggestionItem
                    key={suggestion.key}
                    disabled={isSelected}
                    onClick={e => onClick(e, suggestion, !isSelected)}
                  >
                    {suggestion.label}
                  </SuggestionItem>
                );
              })}
          </Suggestions>
        </>
      )}
    </OutsideClickHandler>
  );
};
export { AutoComplete };
