// @flow
import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    background-color: #e1e7ed;
  }
`;

const Container = styled.div`
  background-color: #e1e7ed;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  align-items: center;
  display: flex;
`;

const Page = ({ testId, children }: { testId: string, children: Node }) => {
  return (
    <>
      <GlobalStyle />
      <Container data-testid={testId}>{children}</Container>
    </>
  );
};

export { Page };
