// @flow
import { Machine, assign } from 'xstate';

const createFormMachine = (
  loadRequest: Function,
  submit: Function,
  onSuccess: Function,
  onError: Function,
) => {
  return Machine(
    {
      id: 'clickButton',
      initial: 'editing',
      states: {
        loading: {
          invoke: {
            id: 'api-call',
            src: loadRequest,
            onDone: {
              target: 'editing',
              actions: assign({
                data: (context, event) => event.data,
              }),
            },
            onError: {
              target: 'error',
            },
          },
        },
        editing: {
          on: {
            SUBMIT: 'submitting',
          },
        },
        submitting: {
          invoke: {
            id: 'submit',
            src: (ctx, e) => submit(e),
            onDone: {
              target: 'success',
            },
            onError: {
              target: 'error',
            },
          },
        },
        success: {
          entry: ['notificationSuccess'],
        },
        error: {
          entry: ['notificationError'],
          on: {
            SUBMIT: 'submitting',
          },
        },
      },
    },
    {
      actions: {
        notificationSuccess: onSuccess,
        notificationError: onError,
      },
    },
  );
};

export { createFormMachine };
