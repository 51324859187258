// @flow
import React from 'react';
import styled from 'styled-components';
import { media } from '../theme';

const Container = styled.footer`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  ${media.max.small`
    flex-direction: column;
  `};
  background-color: ${({ theme }: { theme: Object }) =>
    theme.bgColor ? theme.bgColor : '#f0f3f6'};
  color: ${({ theme }: { theme: Object }) =>
    theme.color ? theme.color : '#242424'};
  font-size: 10px;
  letter-spacing: 0;
  padding: 2px 0px 2px 30px;
  margin-top: auto;
  width: 100%;
`;

const Copyright = styled.span`
  line-height: 24px;
`;

const SocialContainer = styled.span`
  display: flex;
  flex-direction: row;
  margin-right: 30px;
`;

const SocialLink = styled.a`
  text-decoration: none;
`;

const SocialIcon = styled.img`
  height: 16px;
  margin: 3px 4px 2px 4px;
`;

const Phone = styled.span`
  display: inline-block;
  color: ${({ theme }: { theme: Object }) =>
    theme.color ? theme.color : '#242424'};
  margin: 7px 4px 5px 4px;
`;

const Footer = ({ theme }: { theme: Object }) => {
  return (
    <Container theme={theme} data-testid="footer-container">
      <Copyright data-testid="footer-copyright">
        © MTK Connect Limited t/a Connect-Auto {new Date().getFullYear()}
      </Copyright>
      <SocialContainer data-testid="footer-social">
        <SocialLink href="tel:+442083352050" title="Phone">
          <Phone theme={theme}>+44 (0)20 8335 2050</Phone>
        </SocialLink>
        <SocialLink href="mailto:support@connect.auto" title="Email">
          <SocialIcon src={theme.social.email} alt="Email" />
        </SocialLink>
        <SocialLink
          href="https://www.linkedin.com/company/m-connect-auto"
          target="_blank"
          title="LinkedIn"
        >
          <SocialIcon src={theme.social.linkedIn} alt="LinkedIn" />
        </SocialLink>
      </SocialContainer>
    </Container>
  );
};

export { Footer };
