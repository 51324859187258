// @flow
import React from 'react';
import styled from 'styled-components';
import { theme } from '../theme';
import { useAuthState } from '../auth';

const Container = styled.div`
  width: 100%;
`;

const Dealer = styled.button`
  color: #37526b;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-flow: row nowrap;
  box-sizing: border-box;
  width: 100%;
  min-height: 60px;
  margin: 2px 0;
  padding: 20px;
  border: 0;
  background-color: #f6f6f6;
  &:hover {
    background-color: #ebebeb;
  }
`;

const Name = styled.span`
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
`;

const ArrowIcon = styled.img`
  margin-left: 15px;
`;

const Dealers = ({ dealers }: { dealers: Object }) => {
  const { send } = useAuthState();
  return (
    <Container data-testid="dealers-list">
      {dealers &&
        Object.keys(dealers).map((dealerId, i) => (
          <Dealer
            onClick={() => send('DEALER_SELECTED', { dealerId })}
            key={i.toString()}
          >
            <Name>{dealers[dealerId].name}</Name>
            <ArrowIcon src={theme.dealerSelect.arrowIcon} alt="select dealer" />
          </Dealer>
        ))}
    </Container>
  );
};

export { Dealers };
