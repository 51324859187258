// @flow
import React, { createContext } from 'react';
import type { Node } from 'react';

const ConfigContext = createContext<Object>();

const ConfigProvider = ({
  children,
  config,
}: {
  children: Node,
  config: Object,
}) => (
  <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
);

export { ConfigProvider, ConfigContext };
