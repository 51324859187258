// Utility functions
const objectFilter = (obj, predicate) =>
  obj
    ? Object.assign(
        ...Object.keys(obj)
          .filter(key => predicate(obj[key]))
          .map(key => ({ [key]: obj[key] })),
      )
    : obj;

export { objectFilter };
