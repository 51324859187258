// @flow
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useAuthState } from '../auth';

const Button = styled.button`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  height: 26px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 0.1);
  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
`;

const EditIcon = styled.img`
  margin-right: 5px;
`;

const Text = styled.div`
  color: #ffffff;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
  margin-right: 5px;
`;

const ChangeDealer = () => {
  const { send } = useAuthState();
  const { t } = useTranslation();

  return (
    <Button
      data-testid="button-change-dealer"
      onClick={() => send('DEALER_SELECT')}
    >
      <EditIcon
        src="https://res.cloudinary.com/motortrak/image/upload/v1597761150/StaffArea/icon_edit_white.svg"
        alt={t('changeDealer')}
      />
      <Text>{t('changeDealer')}</Text>
    </Button>
  );
};

export { ChangeDealer };
