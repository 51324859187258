// @flow
import React, { createContext } from 'react';
import type { Node } from 'react';

const UserContext = createContext<?Object>();

const UserProvider = ({
  children,
  state,
}: {
  children: Node,
  state: Object,
}) => <UserContext.Provider value={state}>{children}</UserContext.Provider>;

export { UserProvider, UserContext };
