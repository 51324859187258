import styled from 'styled-components';

const ButtonTemplate = styled.button`
  display: flex;
  width: ${props => props.width || 'auto'};
  justify-content: center;
  align-items: center;
  width: 158px;
  height: 38px;
  border-radius: 4px;
  background-color: #4ac57e;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17px;
  text-align: center;
  border: none;
  box-sizing: border-box;
  cursor: pointer;
  outline: none;
  white-space: nowrap;
  font-size: 14px;
  text-decoration: none;
`;

const PrimaryButton = styled(ButtonTemplate)`
  background-color: ${props => (props.disabled ? '#4ac57e' : '#4ac57e')};
  flex-direction: row;
  color: ${props => (props.disabled ? '#334F3F' : '#334F3F')};
`;

const SecondaryButton = styled.button`
  display: flex;
  width: ${props => props.width || 'auto'};
  justify-content: center;
  align-items: center;
  width: 158px;
  height: 38px;
  letter-spacing: 0;
  line-height: 17px;
  text-align: center;
  cursor: pointer;
  outline: none;
  white-space: nowrap;
  box-sizing: border-box;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 4px;
  background-color: transparent;
  color: #ffffff;
  font-size: 14px;
`;

export { PrimaryButton, SecondaryButton };
