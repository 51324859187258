// @flow
import React from 'react';
import type { Node } from 'react';
import styled from 'styled-components';
import IconHint from '../assets/icon_hint.svg';

const InfoBoxContents = styled.div`
  border: 1px solid #bdc7d3;
  border-radius: 2px;
  background-color: rgba(0, 122, 255, 0.1);
  padding: 0 10px;
  display: flex;
  flex-direction: row;
  height: fit-content;
`;

const InfoBoxCol = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 100%;
  margin-bottom: 20px;
`;
const HintIcon = styled.div`
  display: flex;
  background-image: url(${IconHint});
  background-repeat: no-repeat;
  margin: 21px 5px 5px 5px;
  height: 15px;
  width: 18px;
`;
const InfoBoxHeading = styled.div`
  color: #077aff;
  font-size: 12px;
  font-weight: 500;
  margin-top: 20px;
`;
const InfoBoxDescription = styled.div`
  padding-right: 10px;
  color: #283643;
  font-size: 12px;
  line-height: 16px;
`;

const InfoBoxCta = styled.div`
  padding-right: 10px;
  margin-top: 10px;
`;

const InfoBox = ({
  title,
  description,
  children,
}: {
  title: string,
  description: string,
  children?: Node,
}) => (
  <InfoBoxContents>
    <HintIcon />
    <InfoBoxCol>
      <InfoBoxHeading>{title}</InfoBoxHeading>
      <InfoBoxDescription>
        {description.split('\n').map(text => (
          <p key={text.substring(0, 5)}>{text}</p>
        ))}
      </InfoBoxDescription>
      <InfoBoxCta>{children}</InfoBoxCta>
    </InfoBoxCol>
  </InfoBoxContents>
);

export { InfoBox };
